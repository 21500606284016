import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@talves/gatsby-theme-site-layout';

import PostsListItem from '../../components/Posts/item';

/*
  Query here allows for shadowing components
*/
export const query = graphql`
  query($id: String!) {
    post: garden(id: { eq: $id }) {
      ...GardenCollection
    }
  }
`;

const PostsItem = ({ data, pageContext }) => {
  const { collection: returnPath } = pageContext;
  const { title = '' } = data.post.frontmatter;
  return (
    <Layout header={title} frontmatter={data.post.frontmatter}>
      <PostsListItem data={data} returnPath={returnPath} />
    </Layout>
  );
};

export default PostsItem;
