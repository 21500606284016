import React from 'react';
import { GatsbyLinkButton } from '@talves/gatsby-theme-components-tui';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { default as components } from '../../components';
import { useThemeUI } from '../../provider';

const { Box } = components;

function getParameterByName(name, url) {
  if (!url) return null;
  //   url =
  //     typeof window === 'object' && window.location ? window.location.href : '';
  name = name.replace(/[[]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const PostsListItem = ({ data, returnPath = '/' }) => {
  const { theme } = useThemeUI();
  const update = data.post.frontmatter.update || data.post.frontmatter.date;
  const updated = new Date(Date.parse(update));
  const searchString = getParameterByName(
    'q',
    data.post.frontmatter.returnPath,
  );
  return (
    <Box>
      <GatsbyLinkButton sx={{ mr: 10 }} to={returnPath}>
        All Posts
      </GatsbyLinkButton>
      {searchString ? (
        <GatsbyLinkButton to={data.post.frontmatter.returnPath}>
          Posts filtered by: {searchString}
        </GatsbyLinkButton>
      ) : null}

      <Box as="h1" sx={{ mt: '20px' }}>
        {data.post.frontmatter.title}
      </Box>
      <Box
        sx={{
          fontSize: ['0.8em', '0.9em', '1em'],
          padding: ['0.8em', '0.9em', '1em'],
          borderBottom: `1px solid ${theme.colors.primary}`,
        }}
      >
        Updated:{' '}
        {updated.toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}{' '}
        by {data.post.frontmatter.author.info.name}
        {data.post.frontmatter.garden ? (
          <Box
            sx={{
              float: 'left',
              border: `1px solid ${theme.colors.primary}`,
              borderRadius: 4,
              background: `url(/images/garden/${data.post.frontmatter.garden}.svg), ${theme.colors.secondary}`,
              backgroundSize: [50, 60, 80],
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: [50, 60, 80],
              width: [50, 60, 80],
              mr: [20, 20, 30],
            }}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          mt: [30, 35, 40],
          fontSize: [1, 2, 3],
          '& p': { pl: '20px', pb: ['15px', '20px', '30px'] },
          ul: { pl: '40px', pb: ['15px', '20px', '30px'] },
          '& a': {
            appearance: 'none',
            border: 0,
            borderRadius: ['6px', '8px', '10px'],
            color: 'text',
            fontWeight: 'bold',
            padding: '0.1em',
            cursor: 'pointer',
            transition: 'background-size 0.2s linear',
            textDecoration: 'none',
            backgroundImage: `linear-gradient(${theme.colors.background},${theme.colors.accent}), linear-gradient(${theme.colors.background}, ${theme.colors.accent}), linear-gradient(${theme.colors.accent},${theme.colors.background})`,
            backgroundSize: '50% 0.3em, 50% 0.3em, 0 0',
            backgroundPosition: '0 100%, 100% 100%, 50% 0',
            backgroundRepeat: 'no-repeat',
            '&:hover': {
              backgroundSize: '0 2px, 0 2px, 100% 0.75em',
            },
          },
        }}
      >
        <MDXProvider components={components}>
          <MDXRenderer>{data.post.parent.body}</MDXRenderer>
        </MDXProvider>
      </Box>
      {false && <Box as="pre">{JSON.stringify(theme, null, 2)}</Box>}
    </Box>
  );
};

export default PostsListItem;
