import React from 'react';
import { Box } from '../../provider';
import CodeBlock from '../../components/CodeBlock';
import BlockQuote from '../../components/BlockQuote';
import OutsideLink from '../../components/OutsideLink';

const components = {
  Box,
  OutsideLink,
  code: CodeBlock,
  blockquote: BlockQuote,
  pre: props => <Box sx={{ mb: ['10px', '15px', '20px'] }} {...props}></Box>,
};

export default components;
